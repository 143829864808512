import React from 'react';
import DraggableCore from 'react-draggable';
import {INVALID_MOVE} from "boardgame.io/core";

const wunby_die_images = ["repeat","jump","wild","1B","2B","3B"]

export class WunbyBoard extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
        ghostWallStatus: {
            x: -10,
            y: -10,
            length: 0
        },
    };

    this.updateGhostWall = this.updateGhostWall.bind(this);

  }

  onClick(x,y) {

    //console.log(x + ',' + y);

    if (x%2===1) {

      console.log('a horizontal wall slot');

    } else if (y%2===1) {

      console.log('a vertical wall slot');

    } else {

      console.log('this is a square');

    }

    /*
    if (this.isActive(id)) {
      this.props.moves.clickCell(id);
      this.props.endTurn();
    }
    */

  }

  render() {

    let winner = '';
    if (this.props.ctx.gameover !== undefined) {
      winner = <div>Winner: {this.props.ctx.gameover}</div>;
    }

    let dice = <Dice selectedDie={this.props.G.selectedDie} dice={this.props.G.dice} chooseDie={this.props.moves.ChooseDie} roll={this.props.moves.Roll}></Dice>;
    let ghostWall = <GhostWall status={this.state.ghostWallStatus}/>;

    let walls = [];
    let pegs = [];
    let tbody = [];
    let gridLabels = [];
    let cellContents = '';
    let wallLength = 0;
    const pegColors = {r: 'red',
                       g: 'green',
                       b: 'blue',
                       y: 'yellow'};

    for (let y = 0; y < 13; y++) {
      gridLabels.push(<GridLabel key={'x' + y} x={y} y={-1.4} txt={y}></GridLabel>);
      gridLabels.push(<GridLabel key={'y' + y} x={-1.4} y={y} txt={y}></GridLabel>);
      let cellRow = [];
      for (let x = 0; x < 13; x++) {
        const key = 13 * y + x;
        const id = x + ',' + y;
        cellRow.push(
          <td id={'td' + id} key={key}
            onClick={() => this.onClick(x,y)}>
          </td>
        );
        cellContents = this.props.G.cells[y][x];
        if (cellContents in pegColors) {
            pegs.push(<Peg key={(cellContents + key)} x={x} y={y} color={pegColors[cellContents]} movePeg={this.props.moves.MovePeg}></Peg>);
        } else {
            wallLength = parseInt(cellContents);
            if (Number.isInteger(wallLength) && (wallLength > 0)) {
                walls.push(<Wall key={(cellContents + 'B' + key)} x={x} y={y} length={wallLength} cells={this.props.G.cells} dice={this.props.G.dice} moveWall={this.props.moves.MoveWall} updateGhostWall={this.updateGhostWall} ghostWallStatus={this.state.ghostWallStatus}></Wall>);
            }
        }
      }
      tbody.push(<tr key={y}>{cellRow}</tr>);
    }

    let boardStyle = {
      float: 'left',
    };

    return (
      <div>
      <div id="board-container" style={boardStyle}>
        <table id="board">
        <tbody>{tbody}</tbody>
        </table>
        {walls}
        {pegs}
        {gridLabels}
        {ghostWall}
      </div>
      {winner}
      {dice}
      </div>
    );
  }

  updateGhostWall(status) {
      this.setState({ghostWallStatus:status});
  }

}

class Wall extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      x: this.props.x,
      y: this.props.y,
      orientation: this.props.orientation
    };

    this.handleDrag = this.handleDrag.bind(this);
    this.handleStop = this.handleStop.bind(this);

  }

  render() {
    let vertical = (this.state.y % 2 === 0);
    /* If y is even, then x is odd and this is a vertical wall.
       If y is odd, then x is even and this is a horizontal wall.
       If both x and y are even, can't render a wall here because this is a peg square. If both x
       and y are odd, can't render a wall here because this is a vertex. Should we check for
       different oddness before rendering, or just assume that the board data is good? */
    let wallStyle = {
      position: 'absolute',
      width: ((vertical ? 10 : (this.props.length * 50) + ( (this.props.length - 1) * 10 )) - 2 ).toString() + 'px',
      height: ((vertical ? (this.props.length * 50) + ( (this.props.length - 1) * 10 ) : 10) - 2 ).toString() + 'px',
      background: 'black',
      border: '1px solid white'
    };

    let position = {
      x: (this.state.x * 30) + (vertical ? 20 : 0),
      y: (this.state.y * 30) + (vertical ? 0 : 20),
    };

    let offset = {
      x: 0,
      y: -410,
    };

    return (
      <DraggableCore position={position} positionOffset={offset}
                 onStart={this.handleStart}
                 onDrag={this.handleDrag}
                 onStop={this.handleStop}>
        <div style={wallStyle}></div>
      </DraggableCore>
    );

  }

  handleDrag(e, data) {
    /* See if the current pixel coordinates correspond to a vertical and/or horizontal wall slot.
       If so, guess the intended cells[y][x] coordinates for dropping the wall here, and if a
       legal drop location is found then highlight it with a "ghost wall."
       If the mouse is near a vertex, first test the wall location that would maintain the current
       wall orientation, and only check for a rotated drop location if that fails.
     */

    function nearbyCellCoord(pixelCoord) {
        let intPart = Math.trunc(pixelCoord);
        let decimalPart = pixelCoord - intPart;
        if (decimalPart > 0.7) {
            intPart++;
        } else if (decimalPart >= 0.3) {
            return null;
        }
        return intPart;
    }

    function testMoveWall(cells, fromX, fromY, toX, toY) {
        
        /* Skipping the dice checks... mainly so we don't have to pass dice and selectedDie into
           this function, but also because on a UI level the draggability of walls should be set
           at the time of die selection.
        
        //Several checks on this move's validity. First, is the Wunby die selected?
        if (G.selectedDie !== 1) {
            //console.log ('INVALID_MOVE: walls cannot be moved unless Wunby die is selected');
            return false;
        } */

        //Are the "from" coodinates valid?
        if ((!Number.isInteger(fromX)) ||
          (!Number.isInteger(fromY)) ||
          (fromX < 0) ||
          (fromY < 0) ||
          (fromX >= cells.length) ||
          (fromY >= cells.length)) {
            //console.log ('INVALID_MOVE: ' + fromX + ',' + fromY + ' is not a valid wall location');
            return false;
        }

        //Is there a wall at the "from" coordinates?
        let wallLength = parseInt(cells[fromY][fromX]);
        if (!Number.isInteger(wallLength) || (wallLength <= 0)) {
            //console.log ('INVALID_MOVE: There is not a wall at ' + fromX + ',' + fromY);
            return false;
        }

        /*  
        //Does the wall at the "from" coordinates match die roll?
        if ((G.dice[1] !== 0) && (G.dice[1] !== wallLength)) {
            //console.log ('INVALID_MOVE: The wall at ' + fromX + ',' + fromY + ' cannot be moved with the current roll');
            return false;
        }
        */

        //Are the "to" coodinates non-negative integers with different oddness?
        if ((!Number.isInteger(toX)) ||
          (!Number.isInteger(toY)) ||
          (toX < 0) ||
          (toY < 0) ||
          (toX % 2 === toY % 2)) {
            //console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is not a valid wall position');
            return false;
        }

        //Given its length, would this wall fit on the board without hanging over?
        let vertical = (toY % 2 === 0);
        let maxPosition = cells.length - (2 * wallLength) + 1;
        if (vertical) {
            if ((toX >= cells.length) || (toY > maxPosition)) {
                //console.log ('INVALID_MOVE: A vertical ' + wallLength + '-B at ' + toX + ',' + toY + ' would be off the board');
                return false;
            }
        } else {
            if ((toX > maxPosition) || (toY >= cells.length)) {
                //console.log ('INVALID_MOVE: A horizontal ' + wallLength + '-B at ' + toX + ',' + toY + ' would be off the board');
                return false;
            }
        }

        //Are the "to" coodinates currently empty? (This will also catch using same to and from coordinates)
        if (cells[toY][toX] !== ' ') {
            //console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is not currently empty');
            return false;
        }

        //Check for collisions with other walls (excluding this wall's own "from" position)
        if (vertical) {
            //Check walls in the same vertical slot: long ones at lower y coordinates that would
            //overlap, and then any at higher y coordinates that this wall's length would overlap
            for (let y = 0; y <= cells.length; y += 2) {
                if ((y !== fromY) && (cells[y][toX] !== ' ')) {
                    if ((y < toY) && (y + (2 * parseInt(cells[y][toX])) > toY)) {
                        //console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is blocked by the ' + cells[y][toX] + '-B at ' + toX + ',' + y);
                        return false;
                    }
                    if ((y > toY) && (toY + (2 * wallLength) > y)) {
                        //console.log ('INVALID_MOVE: A ' + wallLength + '-B at ' + toX + ',' + toY + ' would overlap the ' + cells[y][toX] + '-B at ' + toX + ',' + y);
                        return false;
                    }
                }
            }
            //Then make sure no long horizontal walls are blocking verteces that this wall needs
            for (let y = toY + 1; y < toY + 2 * (wallLength - 1); y += 2) {
                //console.log('checking perpendicular walls at y=' + y);
                for (let x = 0; x < toX; x += 2) {
                    if (((x !== fromX) || (y !== fromY)) && (x - 1 + (2 * parseInt(cells[y][x])) > toX) ) {
                        //console.log ('INVALID_MOVE: A vertical ' + wallLength + '-B at ' + toX + ',' + toY + ' would be blocked by the horizontal ' + cells[y][x] + '-B at ' + x + ',' + y);
                        return false;
                    }
                }
            }
        } else {
            //Check walls in the same horizontal slot: long ones at lower x coordinates that would
            //overlap, and then any at higher x coordinates that this wall's length would overlap
            for (let x = 0; x <= cells.length; x += 2) {
                if ((x !== fromX) && (cells[toY][x] !== ' ')) {
                    if ((x < toX) && (x + (2 * parseInt(cells[toY][x])) > toX)) {
                        //console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is blocked by the ' + cells[toY][x] + '-B at ' + x + ',' + toY);
                        return false;
                    }
                    if ((x > toX) && (toX + (2 * wallLength) > x)) {
                        //console.log ('INVALID_MOVE: A ' + wallLength + '-B at ' + toX + ',' + toY + ' would overlap the ' + cells[toY][x] + '-B at ' + x + ',' + toY);
                        return false;
                    }
                }
            }
            //Then make sure no long vertical walls are blocking vertices that this wall needs
            for (let x = toX + 1; x < toX + 2 * (wallLength - 1); x += 2) {
                //console.log('checking perpendicular walls at x=' + x);
                for (let y = 0; y < toY; y += 2) {
                    if (((x !== fromX) || (y !== fromY)) && (y - 1 + (2 * parseInt(cells[y][x])) > toY) ) {
                        //console.log ('INVALID_MOVE: A horizontal ' + wallLength + '-B at ' + toX + ',' + toY + ' would be blocked by the vertical ' + cells[y][x] + '-B at ' + x + ',' + y);
                        return false;
                    }
                }
            }
        }

        //Everything checks out... This is a valid place for a ghost wall!
        return true;
    }

    let ghostWallStatus = {x: -10, y: -10, length: 0};
    let vertical = (this.state.y % 2 === 0);
    let wallHalfPixelLength = this.props.length * 30 - 6;
    if (vertical) {
        let verticalWallSlotX = nearbyCellCoord(((data.x - 20) / 30));
        console.log('checking for a drop location for this currently-vertical ' + this.props.length + '-B, verticalWallSlotX calculated as ' + verticalWallSlotX);
        if (Number.isInteger(verticalWallSlotX) && (verticalWallSlotX % 2 === 1)) {
            //This vertical wall is being dragged over a vertical slot. Does the wall's y position
            //line up with a peg square?
            let verticalWallTopY = nearbyCellCoord((data.y - 4) / 30);
            console.log('e.y=' + e.y + ' wall top calculated as cell y=' + verticalWallTopY);
            if (Number.isInteger(verticalWallTopY) && (verticalWallTopY % 2 === 0)) {
                //The wall is being dragged over a possible vertical wall position. If this is a
                //legal move for this wall, set the ghost wall coordinates.
                if (testMoveWall(this.props.cells, this.state.x, this.state.y, verticalWallSlotX, verticalWallTopY)) {
                    ghostWallStatus = {x: verticalWallSlotX,
                                       y: verticalWallTopY,
                                       length: this.props.length};
                }
            }
        }
        if (ghostWallStatus.length === 0) {
            //This vertical wall is not being dragged over a viable vertical location. Is the mouse
            //cursor on a horizontal wall slot?
            let mouseCellY = nearbyCellCoord((e.y - 50) / 30);
            if (Number.isInteger(mouseCellY) && (mouseCellY % 2 === 1)) {
                //Build an array of legal positions for this wall in this horizontal slot, and
                //reduce it to the position that most closely matches the mouse (if any do.)
                let horizontalWallPositions = [];
                for (let x = 0; x < 13; x += 2) {
                    if (testMoveWall(this.props.cells, this.state.x, this.state.y, x, mouseCellY)) {
                        horizontalWallPositions.push(x);
                    }
                }
                console.log(horizontalWallPositions);
                let closestCellX = horizontalWallPositions.reduce((previousX, currentX) => {
                    let mousePixelDistanceFromWallCenter = Math.abs(e.x - ((currentX * 30) + wallHalfPixelLength + 24));
                    console.log('the center of a horizontal ' + this.props.length + '-B at x=' + currentX + ' would be ' + mousePixelDistanceFromWallCenter + ' away from mouse');
                    if ((mousePixelDistanceFromWallCenter <= wallHalfPixelLength) &&
                        ((previousX < 0) || (mousePixelDistanceFromWallCenter < Math.abs(e.x - ((previousX * 30) + wallHalfPixelLength + 24))))) {
                            return currentX;
                        }
                        return previousX;
                    } , -1);
                console.log('closest valid horizontal wall x coord is ' + closestCellX);
                if (closestCellX > -1) {
                    ghostWallStatus = {x: closestCellX,
                                       y: mouseCellY,
                                       length: this.props.length};
                }
            }
        }
    } else {
        let horizontalWallSlotY = nearbyCellCoord(((data.y - 20) / 30));
        console.log('checking for a drop location for this currently-horizontal ' + this.props.length + '-B, horizontalWallSlotY calculated as ' + horizontalWallSlotY);
        if (Number.isInteger(horizontalWallSlotY) && (horizontalWallSlotY % 2 === 1)) {
            //This horizontal wall is being dragged over a horizontal slot. Does the wall's x position
            //line up with a peg square?
            let horizontalWallLeftX = nearbyCellCoord((data.x - 4) / 30);
            console.log('e.x=' + e.x + ' wall left calculated as cell x=' + horizontalWallLeftX);
            if (Number.isInteger(horizontalWallLeftX) && (horizontalWallLeftX % 2 === 0)) {
                //The wall is being dragged over a possible horizontal wall position. If this is a
                //legal move for this wall, set the ghost wall coordinates.
                if (testMoveWall(this.props.cells, this.state.x, this.state.y, horizontalWallLeftX, horizontalWallSlotY)) {
                    ghostWallStatus = {x: horizontalWallLeftX,
                                       y: horizontalWallSlotY,
                                       length: this.props.length};
                }
            }
        }
        if (ghostWallStatus.length === 0) {
            //This horizontal wall is not being dragged over a viable horizontal location. Is the
            //mouce cursor on a vertical wall slot?
            let mouseCellX = nearbyCellCoord((e.x - 50) / 30);
            if (Number.isInteger(mouseCellX) && (mouseCellX % 2 === 1)) {
                //Build an array of legal positions for this wall in this vertical slot, and
                //reduce it to the position that most closely matches the mouse (if any do.)
                let verticalWallPositions = [];
                for (let y = 0; y < 13; y += 2) {
                    if (testMoveWall(this.props.cells, this.state.x, this.state.y, mouseCellX, y)) {
                        verticalWallPositions.push(y);
                    }
                }
                console.log(verticalWallPositions);
                let closestCellY = verticalWallPositions.reduce((previousY, currentY) => {
                    let mousePixelDistanceFromWallCenter = Math.abs(e.y - ((currentY * 30) + wallHalfPixelLength + 24));
                    console.log('the center of a vertical ' + this.props.length + '-B at y=' + currentY + ' would be ' + mousePixelDistanceFromWallCenter + ' away from mouse');
                    if ((mousePixelDistanceFromWallCenter <= wallHalfPixelLength) &&
                        ((previousY < 0) || (mousePixelDistanceFromWallCenter < Math.abs(e.y - ((previousY * 30) + wallHalfPixelLength + 24))))) {
                            return currentY;
                        }
                        return previousY;
                    } , -1);
                console.log('closest valid vertical wall y coord is ' + closestCellY);
                if (closestCellY > -1) {
                    ghostWallStatus = {x: mouseCellX,
                                       y: closestCellY,
                                       length: this.props.length};
                }
            }
        }
    }
    this.props.updateGhostWall(ghostWallStatus);
  }

  handleStop(e,data) {

    if (this.props.ghostWallStatus.length === this.props.length) {
        this.props.moveWall(this.state.x, this.state.y, this.props.ghostWallStatus.x, this.props.ghostWallStatus.y)
    }
    this.props.updateGhostWall({x: -10, y: -10, length: 0});
  }
}

class GhostWall extends React.Component {

  // constructor(props) {
  //
  //   super(props);
  //
  //   this.state = {
  //     x: -10,
  //     y: -10,
  //     length: 0
  //   };
  //
  // }

  // componentDidMount() {
  //     updateGhostWall = updateGhostWall.bind(this);
  // }

  render() {
    let vertical = (this.props.status.y % 2 === 0);
    let ghostWallStyle = {
      position: 'absolute',
      left: ((this.props.status.x * 30) + (vertical ? 20 : 0)).toString() + 'px',
      top: ((this.props.status.y * 30) + (vertical ? 0 : 20)).toString() + 'px',
      width: ((vertical ? 10 : (this.props.status.length * 50) + ( (this.props.status.length - 1) * 10 )) - 2 ).toString() + 'px',
      height: ((vertical ? (this.props.status.length * 50) + ( (this.props.status.length - 1) * 10 ) : 10) - 2 ).toString() + 'px',
      background: 'silver',
      border: '1px solid white',
      boxShadow: '0 0 10px 5px white'
    };
    return (<div style={ghostWallStyle}/>);
  }

}

class Peg extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      x: this.props.x,
      y: this.props.y,
    };

    this.handleStop = this.handleStop.bind(this);

  }

  render() {

    var pegStyle = {
      transform: 'none',
      position: 'fixed',
      width: '20px',
      height: '20px',
      background: this.props.color,
      border: '1px solid black',
      'borderRadius': '10px'
    };

    var position = {
      x: ( (this.state.x * 30) + 15),
      y: ( (this.state.y * 30) + 15),
    };

    var offset = {
      x: 0,
      y: -410,
    };

    return (
        <DraggableCore onStart={this.handleStart}
                   onDrag={this.handleDrag}
                   onStop={this.handleStop} position={position} positionOffset={offset}>
          <div style={pegStyle} onClick={this.handleClick}></div>
        </DraggableCore>
    );

  }

  handleStart(e) {

    //e.preventDefault();
    //console.log('start draggion');

  }

  handleDrag(e) {

    //e.preventDefault();
    //e.stopPropagation();

    //console.log('what a drag');

  }

  handleStop(e) {

    var toX = Math.floor((e.x - 30)/60) * 2;
    var toY = Math.floor((e.y - 30)/60) * 2;

    if ( !isNaN(toX) && !isNaN(toY) && toX < 13 && toY < 13) {
      this.props.movePeg(this.state.x, this.state.y, toX, toY);
    }

    //return false; //https://github.com/react-grid-layout/react-draggable/issues/390

  }

  handleClick() {

    //console.log('clicky');

  }

}

class Dice extends React.Component {

  render() {

    let dieStyle = {
      background: 'white',
      height: '50px',
      width: '50px',
      float: 'left',
      border: '5px solid black',
      margin: '5px',
      fontSize: '40px',
      textAlign: 'center'
    };

    const dieStyleFaded = {
      ...dieStyle,
      opacity: '60%'
    }

    return (

        <div style={{float: 'left', width: '140px'}}>

            <div style={this.props.selectedDie === 1 ? dieStyle : dieStyleFaded} onClick={this.chooseWunby}>
                <img width="100%" height="100%" src={'/dice/' + wunby_die_images[this.props.dice[1] + 2] + '.png'}/>
            </div>

            <div style={this.props.selectedDie === 0 ? dieStyle : dieStyleFaded} onClick={this.chooseStandard}>
                <img width="100%" height="100%" src={'/dice/' + this.props.dice[0].toString() + '.png'}/>
            </div>


            <button style={{'margin-left':'5px'}} onClick={this.props.roll}>Roll</button>

        </div>

    );

  }

  chooseStandard = (e) => {

    this.props.chooseDie('standard');

  }

  chooseWunby = (e) => {

    this.props.chooseDie('wunby');

  }

}

class GridLabel extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      x: this.props.x,
      y: this.props.y,
      txt: this.props.txt
    };

  }

  render() {

    var left = ( (this.state.x * 30) + 20);
    if (this.state.x > 9) {
      left = left - 3;
    }
    var top = ( (this.state.y * 30) + 15);

    var labelStyle = {
      position: 'absolute',
      width: '20px',
      height: '20px',
      left: left,
      top: top
    };

    return (
          <div style={labelStyle}>{this.state.txt}</div>
    );

  }

}