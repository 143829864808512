import { INVALID_MOVE } from 'boardgame.io/core';

export const Wunby = {

  setup: () => {
    /* Board state is kept as a 13-element array of length-13 strings. Each string represents a
       horizontal row with the same y coordinate, so individual board cells are accessed in [y][x]
       order. (Eg, cells[0][4] is the fifth cell from the left on the top row.)

       Pegs are represented by a letter indicating color. The "." char represents the empty peg
       hole of a vacant square. Pegs always have even y and x coordinates.

       Walls are represented by a digit indicating length. 2-Bs and 3-Bs are recorded at their
       lowest y and x coordinates. Vertical walls will always have an even y and an odd x, and
       horizontal walls will always have an odd y and an even x.
       */
    var cells = [
        '.2b b b3b b .',
        '          2  ',
        'g .1. . . . y',
        '    2   1 1  ',
        'g .1. . .2. y',
        '3            ',
        'g .2. . . . y',
        '        3    ',
        'g . .3. .1. y',
        '  1 1 2      ',
        'g . . . .1.2y',
        '2            ',
        '. r r r r r .',
    ];


    var wallLengths = [];
    let thisLength = 0;
    for (const column of cells) {
        for (const cell of column) {
            thisLength = parseInt(cell);
            if (Number.isInteger(thisLength) &&
              (thisLength > 0) &&
              !wallLengths.includes(thisLength)) {
                wallLengths.push(thisLength);
            }
        }
    }
    wallLengths.sort((a, b) => b - a);

    var dice = [1, -1];
    var selectedDie = 1; //TEMPORARY, for testing MoveWall

    //console.log(cells);

    return { cells:cells, dice:dice, selectedDie:selectedDie, wallLengths:wallLengths }

  },

  moves: {

    MoveWall(G, ctx, fromX, fromY, toX, toY) {
        //Several checks on this move's validity. First, is the Wunby die selected?
        if (G.selectedDie !== 1) {
            console.log ('INVALID_MOVE: walls cannot be moved unless Wunby die is selected');
            return INVALID_MOVE;
        }

        //Are the "from" coodinates valid?
        if ((!Number.isInteger(fromX)) ||
          (!Number.isInteger(fromY)) ||
          (fromX < 0) ||
          (fromY < 0) ||
          (fromX >= G.cells.length) ||
          (fromY >= G.cells.length)) {
            console.log ('INVALID_MOVE: ' + fromX + ',' + fromY + ' is not a valid wall location');
            return INVALID_MOVE;
        }

        //Is there a wall at the "from" coordinates?
        let wallLength = parseInt(G.cells[fromY][fromX]);
        if (!Number.isInteger(wallLength) || (wallLength <= 0)) {
            console.log ('INVALID_MOVE: There is not a wall at ' + fromX + ',' + fromY);
            return INVALID_MOVE;
        }

        //Does the wall at the "from" coordinates match die roll?
        if ((G.dice[1] !== 0) && (G.dice[1] !== wallLength)) {
            console.log ('INVALID_MOVE: The wall at ' + fromX + ',' + fromY + ' cannot be moved with the current roll');
            return INVALID_MOVE;
        }

        //Are the "to" coodinates non-negative integers with different oddness?
        if ((!Number.isInteger(toX)) ||
          (!Number.isInteger(toY)) ||
          (toX < 0) ||
          (toY < 0) ||
          (toX % 2 === toY % 2)) {
            console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is not a valid wall position');
            return INVALID_MOVE;
        }

        //Given its length, would this wall fit on the board without hanging over?
        let vertical = (toY % 2 === 0);
        let maxPosition = G.cells.length - (2 * wallLength) + 1;
        if (vertical) {
            if ((toX >= G.cells.length) || (toY > maxPosition)) {
                console.log ('INVALID_MOVE: A vertical ' + wallLength + '-B at ' + toX + ',' + toY + ' would be off the board');
                return INVALID_MOVE;
            }
        } else {
            if ((toX > maxPosition) || (toY >= G.cells.length)) {
                console.log ('INVALID_MOVE: A horizontal ' + wallLength + '-B at ' + toX + ',' + toY + ' would be off the board');
                return INVALID_MOVE;
            }
        }

        //Are the "to" coodinates currently empty? (This will also catch using same to and from coordinates)
        if (G.cells[toY][toX] !== ' ') {
            console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is not currently empty');
            return INVALID_MOVE;
        }

        //Check for collisions with other walls (excluding this wall's own "from" position)
        if (vertical) {
            //Check walls in the same vertical slot: long ones at lower y coordinates that would
            //overlap, and then any at higher y coordinates that this wall's length would overlap
            for (let y = 0; y <= G.cells.length; y += 2) {
                if ((y !== fromY) && (G.cells[y][toX] !== ' ')) {
                    if ((y < toY) && (y + (2 * parseInt(G.cells[y][toX])) > toY)) {
                        console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is blocked by the ' + G.cells[y][toX] + '-B at ' + toX + ',' + y);
                        return INVALID_MOVE;
                    }
                    if ((y > toY) && (toY + (2 * wallLength) > y)) {
                        console.log ('INVALID_MOVE: A ' + wallLength + '-B at ' + toX + ',' + toY + ' would overlap the ' + G.cells[y][toX] + '-B at ' + toX + ',' + y);
                        return INVALID_MOVE;
                    }
                }
            }
            //Then make sure no long horizontal walls are blocking verteces that this wall needs
            for (let y = toY + 1; y < toY + 2 * (wallLength - 1); y += 2) {
                console.log('checking perpendicular walls at y=' + y);
                for (let x = 0; x < toX; x += 2) {
                    if (((x !== fromX) || (y !== fromY)) && (x - 1 + (2 * parseInt(G.cells[y][x])) > toX) ) {
                        console.log ('INVALID_MOVE: A vertical ' + wallLength + '-B at ' + toX + ',' + toY + ' would be blocked by the horizontal ' + G.cells[y][x] + '-B at ' + x + ',' + y);
                        return INVALID_MOVE;
                    }
                }
            }
        } else {
            //Check walls in the same horizontal slot: long ones at lower x coordinates that would
            //overlap, and then any at higher x coordinates that this wall's length would overlap
            for (let x = 0; x <= G.cells.length; x += 2) {
                if ((x !== fromX) && (G.cells[toY][x] !== ' ')) {
                    if ((x < toX) && (x + (2 * parseInt(G.cells[toY][x])) > toX)) {
                        console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is blocked by the ' + G.cells[toY][x] + '-B at ' + x + ',' + toY);
                        return INVALID_MOVE;
                    }
                    if ((x > toX) && (toX + (2 * wallLength) > x)) {
                        console.log ('INVALID_MOVE: A ' + wallLength + '-B at ' + toX + ',' + toY + ' would overlap the ' + G.cells[toY][x] + '-B at ' + x + ',' + toY);
                        return INVALID_MOVE;
                    }
                }
            }
            //Then make sure no long vertical walls are blocking vertices that this wall needs
            for (let x = toX + 1; x < toX + 2 * (wallLength - 1); x += 2) {
                console.log('checking perpendicular walls at x=' + x);
                for (let y = 0; y < toY; y += 2) {
                    if (((x !== fromX) || (y !== fromY)) && (y - 1 + (2 * parseInt(G.cells[y][x])) > toY) ) {
                        console.log ('INVALID_MOVE: A horizontal ' + wallLength + '-B at ' + toX + ',' + toY + ' would be blocked by the vertical ' + G.cells[y][x] + '-B at ' + x + ',' + y);
                        return INVALID_MOVE;
                    }
                }
            }
        }

        //Everything checks out... Move that wall!
        G.cells[toY] = G.cells[toY].substring(0,toX) + wallLength + G.cells[toY].substring(toX + 1);
        G.cells[fromY] = G.cells[fromY].substring(0,fromX) + ' ' + G.cells[fromY].substring(fromX + 1);
        console.log ('Moved ' + wallLength + '-B from ' + fromX + ',' + fromY + ' to ' + toX + ',' + toY);
        ctx.events.endStage();
    },

    MovePeg: (G, ctx, fromX, fromY, toX, toY) => {

        console.log('Attempting to move peg from ' + fromX + ',' + fromY + ' to ' + toX + ',' + toY + '. Dice are [' + G.dice[0] + ',' + G.dice[1] + ']. Die ' + G.selectedDie + ' is selected.');

        function posIsOnBoard(x, y) {
            return (x >= 0) && (x < G.cells.length) && (y >= 0) && (y < G.cells.length);
        }

        function nextMoves(x, y, jump=false) {

            function moveIsPossible(fromX, fromY, toX, toY, jump) {
                console.log('Checking move from ' + fromX + ',' + fromY + ' to ' + toX + ',' + toY);
                if (!posIsOnBoard(toX, toY)) {
                    console.log('  ' + toX + ',' + toY + ' is off the board, returning false');
                    return false;
                }
                if (fromX === toX) {
                    //attempting verical movement, is there a horizontal wall between fromY and toY?
                    let wallY = Math.floor((fromY + toY) / 2);
                    for (let x = 0; x <= toX; x+= 2) {
                        console.log('  - looking for a horizontal wall at ' + x + ',' + wallY);
                        if (x + (parseInt(G.cells[wallY][x]) * 2) > toX) {
                            console.log('  the ' + parseInt(G.cells[wallY][x]) + '-B at ' + x + ',' + wallY + ' is in the way, only jump is possible');
                            return jump;
                        }
                    }
                } else {
                    //attempting horizontal movement, is there a vertical wall between fromX and toX?
                    let wallX = Math.floor((fromX + toX) / 2);
                    for (let y = 0; y <= toY; y+= 2) {
                        console.log('  - looking for a vertical wall at ' + wallX + ',' + y);
                        if (y + (parseInt(G.cells[y][wallX]) * 2) > toY) {
                            console.log('  the ' + parseInt(G.cells[y][wallX]) + '-B at ' + wallX + ',' + y + ' is in the way, only jump is possible');
                            return jump;
                        }
                    }
                }
                console.log('  not blocked by a wall, move is possible but not jump');
                return !jump;
            }

            const nexts = [];
            if (moveIsPossible(x, y, x - 2, y, jump)) {
                nexts.push({x: x - 2, y: y})
            }
            if (moveIsPossible(x, y, x + 2, y, jump)) {
                nexts.push({x: x + 2, y: y})
            }
            if (moveIsPossible(x, y, x, y - 2, jump)) {
                nexts.push({x: x, y: y - 2})
            }
            if (moveIsPossible(x, y, x, y + 2, jump)) {
                nexts.push({x: x, y: y + 2})
            }
            return nexts;
        }

        function pathIncludesPos(path, x, y) {
            return path.some( (pos) => ((pos.x === x) && (pos.y === y)) );
        }

        function continuePaths(oldPaths) {
            const continuedPaths = [];
            for (const path of oldPaths) {
                for (const next of nextMoves(path[0].x, path[0].y)) {
                    if (!pathIncludesPos(path, next.x, next.y)) {
                        continuedPaths.push(Array(next).concat(path));
                    }
                }
            }
            return continuedPaths;
        }

        function pathEndsAtPos(path, x, y) {
            return ((x === path[0]?.x) && (y === path[0]?.y));
        }

        //Are the "from" coodinates valid?
        if ((!Number.isInteger(fromX)) ||
          (!Number.isInteger(fromY)) ||
          (!posIsOnBoard(fromX, fromY))) {
            console.log ('INVALID_MOVE: ' + fromX + ',' + fromY + ' is not a valid peg location');
            return INVALID_MOVE;
        }

        //Is there a peg at the "from" coordinates?
        const color = G.cells[fromY][fromX];
        if (color === '.') {
            console.log ('INVALID_MOVE: There is not a peg at ' + fromX + ',' + fromY);
            return INVALID_MOVE;
        }

        //Are the "to" coodinates valid?
        if ((!Number.isInteger(toX)) ||
          (!Number.isInteger(toY)) ||
          (!posIsOnBoard(toX, toY)) ||
          (toX %2 === 1) ||
          (toY %2 === 1)) {
            console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is not a valid peg destination');
            return INVALID_MOVE;
        }

        //Are the "to" coordinates blocked by a peg of the same color?
        if (G.cells[toY][toX] === color) {
            console.log ('INVALID_MOVE: ' + toX + ',' + toY + ' is occupied by a peg of the same color');
            return INVALID_MOVE;
        }
        /* NOTE: We also need to check whether there's a different-colored peg at the destination
           coordinates! If so, it needs to be captured and relocated.

           If we implement the relocation of captured pegs by passing an optional third set of x
           and y coordinates to the MovePeg() function, then here's where we'd probably want to
           evaluate the presence or absence of those capture coordinates, and return ILLEGAL_MOVE
           if things don't look kosher.
           */
        let fromPos = {x: fromX, y: fromY};
        let paths = Array();

        if ((G.selectedDie === 0) || (G.dice[1] === -2) || (G.dice[1] === 0)) {
            //Standard peg movement is allowed, populate the paths array with any legal moves
            //for this peg that match the roll on the standard movement die.
            paths.push(Array(fromPos));
            for (let i = 1; i <= G.dice[0]; i++) {
                for (const newPath of continuePaths(paths.filter( (p) => { return p.length === i } ))) {
                    paths.push(newPath);
                }
            }
            paths = paths.filter( (p) => { return p.length === (G.dice[0] + 1) } );
        }

        if ((G.selectedDie === 1) && ((G.dice[1] === -1) || (G.dice[1] === 0))) {
            //Jumps are allowed, so populate the paths array with any legal jump destinations
            nextMoves(fromX, fromY, true).forEach( toPos => paths.push([toPos]) );
        }

        console.log('Possible peg movement paths:');
        console.log(paths);

        if (!paths.some((path) => pathEndsAtPos(path, toX, toY))) {
            console.log ('INVALID_MOVE: the peg at ' + fromX + ',' + fromY + " can't reach " + toX + ',' + toY + ' with the selected die roll');
            return INVALID_MOVE;
        }

        //Destination checks out... Move that peg!
        G.cells[toY] = G.cells[toY].substring(0,toX) + color + G.cells[toY].substring(toX + 1);
        G.cells[fromY] = G.cells[fromY].substring(0,fromX) + '.' + G.cells[fromY].substring(fromX + 1);
        console.log('Moved "' + color + '" peg from ' + fromX + ',' + fromY + ' to ' + toX + ',' + toY);
        /* NOTE: If we implement the relocation of captured pegs using a seperate move stage within
           the turn, here's where we'd need to:
           1) record the captured peg color in the G object
           2) set the next stage to require a peg placement move */

        ctx.events.endStage();
    },

    ChooseDie: (G, ctx, d) => {
      switch(d) {
        case 'wunby':
          G.selectedDie = 1;
          ctx.events.setStage('wunby');
          break;
        default:
          G.selectedDie = 0;
          ctx.events.setStage('standard');
      }
    },

    Roll: (G, ctx) => {

        return RollDice(G, ctx);

    }

  },

  endIf: (G, ctx) => {

  },

  /*phases: {


    place: {
      moves: { PlaceWall },
      start: true,
    },


    play: {
      start: true,
      onBegin: () => {
        console.log('begin play phase')
      }
    },

  }, */

  turn: {
    onBegin: (G, ctx) => {

        /* Automatically roll dice at the start of each turn.
        */

        RollDice(G,ctx);

    },

    stages: {

        standard: {

             // moves: {
             //   MovePeg
             // },

        },

        wunby: {

            // moves: {
            //     MoveWall,
            // },

        },

      // custom_die_jump: {
      //
      //   moves: {
      //     Jump(G, ctx) {},
      //   },
      //
      // },
      //
      // custom_die_wall: {
      //
      //   moves: {
      //     MoveWall(G, ctx) {},
      //   }
      //
      // },
      //
      // custom_die_wild: {
      //
      //   moves: {
      //
      //     ChooseWild(G, ctx) {},
      //
      //   },
      //
      // },
      //
      // capture: {
      //
      //   moves: {
      //
      //     RepositionCaptured(G,ctx) {},
      //
      //   },
      //
      // }

    }

  }

};


let replaceAt = function(str, index, replacement) {
    if (index >= str.length) {
        return str.valueOf();
    }
    return str.substring(0, index) + replacement + str.substring(index + 1);
}

function RollDice(G, ctx)  {

    /* G.dice[0] is the "Movement Die", a
    standard 6-sided die with values ranging from 1 to 6. G.dice[1] is the custom 6-sided
    "Wunby Die", with values ranging from -2 to 3, where -2 represents REPEAT, -1 represents
    JUMP, 0 represents WILD, and 1, 2, 3 represent 1-B, 2-B, 3-B. (The range of the Wunby
    Die could be expanded here to support longer walls -- the wall movement code currently
    supports wall lengths up to 9, which would need 12-sided die.) */

    G.dice[0] = ctx.random.Die(6);
    G.dice[1] = ctx.random.Die(6) - 3;

}


/*

function IsVictory(G) {

  console.log(G.squares);

 // return true;

  return false;
}



function sameAsBarrier(otherBarrier) {

  //Simple-minded comparison function says barrier is the same if the first blockage is the same and the length is the same
  return ((blockages[0][0][0] == otherBarrier[0][0][0]) &&
      (blockages[0][0][1] == otherBarrier[0][0][1]) &&
      (blockages[0][1][0] == otherBarrier[0][1][0]) &&
      (blockages[0][1][1] == otherBarrier[0][1][1]) &&
      this.size == otherBarrier.length);

}

function calculateAllowedMoves(whereTheBarriersAreNow) {


    //    - Returns an array of barrier "places".
    //    - Each "place" the barrier can be moved to is an array of "blockages"
    //    - Each "blockage" is a pair of adjacent "squares", and direct peg movement between these squares will be allowed only by jumping
    //    - Each "square" is a pair of x and y grid coordinates, numbered from the bottom left of the board, starting at zero


		// HOWDY BARRIER I CLICKED YOU TELL ME ABOUT YOURSELF
		// console.log('I am a ' + this.orientation + ' ' + this.size + '-B.');

	//	console.log('my blockages are:');
  //  console.log(this.blockages);

	//	console.log('the board state is:');
	//	console.log(whereTheBarriersAreNow);
	//	console.log('---------');

        //consider passing the boardSize to this function instead of hardcoding it here...
        var boardSize = [7,7];

        //Overlap dictionary objects, used to calculate what positions a chosen wall cannot be
        //moved to. The concept is the same as a "blockage" in the Barrier object -- a pair of
        //squares that already have a wall between them, so a chosen wall cannot be moved there.
        //However, because we have separate dictionaries for horizontal and vertical overlaps, we
        //don't need to specify both squares of the blockage, just the first. The dictionary keys
        //will be coordinate strings in the form of "x,y" and the values will be set to 1 but are not
        //used. The chosen wall may not be moved to any horizontal postion that includes any "x,y"
        //from the currentHorizontalOverlaps dictionary, nor to any vertical postion that includes
        //any "x,y" from the currentVerticalOverlaps dictionary
        //
        //(PLEASE NOTE! In the code that checks the overlaps, I'm making a big assumption about the form
        //of the blockage data in a Barrier object -- namely that both the list of blockages and the two
        //squares within a blockage will be "sorted" from the closest to 0,0 to the furthest. All of our
        //hard-coded starting walls are given in this form, and the new blockage positions that populate
        //the placesYouCanPutThisBarrier array will also be generated in this form, but if we want to be
        //cautious about this we might want to implement a sort function for blockages that will
        //guarantee they are listed this way before checking the overlaps.)
        var currentHorizontalOverlaps = {};
        var currentVerticalOverlaps = {};

        //Intersection overlap dictionary objects... walls larger than 1-B will block the placement of
        //perpendicular walls also larger than 1-B at intersections. An intersection is the center of
        //four squares on the board, but again, for simplicity's sake, we'll only specify the first
        //square (the one closest to 0,0) and the dictionary keys will be "x,y" strings with the
        //coordinates of this first square. Vertical 2-Bs and 3-Bs will populate the
        //currentHorizontalIntersectionOverlaps dictionary, and horizontal 2-Bs and 3-Bs will populate
        //the currentVerticalIntersectionOverlap dictionary. As with the above dictionaries, the values
        //are set to 1 but never used; only the presence of the entry matters.
        var currentHorizontalIntersectionOverlaps = {};
        var currentVerticalIntersectionOverlaps = {};

        //Loop counter and misc vars
        var i;
        var j;
        var x;
        var y;
        var wall;
        var coordKey;
        var testPosition;

        //array to be populated and returned
		var placesYouCanPutThisBarrier = [];

        //Loop through all current wall positions to populate overlap dictionaries
        for (i = 0; i < whereTheBarriersAreNow.length; i++) {
            wall = whereTheBarriersAreNow[i];
            //don't include the current wall's blockages -- it's been "removed" from the board
            if (this.sameAsBarrier(wall)) { continue; }
            if (wall[0][0][0] == wall[0][1][0]) {
                //If this wall's first blockage specifies a pair of squares with the same x coordinate,
                //it's a horizontal wall, and will prevent the player from moving his chosen wall to a
                //location that overlaps any of its horizontal blockages.
                //If it's a horizontal wall bigger than a 1-B, it will also block vertical walls at
                //the intersections.
                for (j = 0; j < wall.length; j++) {
                    coordKey = wall[j][0][0] + ',' + wall[j][0][1];
                    currentHorizontalOverlaps[coordKey] = 1;
                    if (j > 0) {
                        coordKey = wall[j-1][0][0] + ',' + wall[j-1][0][1];
                        currentVerticalIntersectionOverlaps[coordKey] = 1;
                    }
                }
            } else {
                //otherwise it's a vertical wall, which will prevent the player from moving his chosen
                //wall to any location that overlaps the vertical blockages. And, if it's a vertical wall
                //bigger than a 1-B, it will block horizontal placements at the intersections.
                for (j = 0; j < wall.length; j++) {
                    coordKey = wall[j][0][0] + ',' + wall[j][0][1];
                    currentVerticalOverlaps[coordKey] = 1;
                    if (j > 0) {
                        coordKey = wall[j-1][0][0] + ',' + wall[j-1][0][1];
                        currentHorizontalIntersectionOverlaps[coordKey] = 1;
                    }
                }
            }
        }

		console.log('currentHorizontalOverlaps:');
		console.log(currentHorizontalOverlaps);
		console.log('currentHorizontalIntersectionOverlaps:');
		console.log(currentHorizontalIntersectionOverlaps);
		console.log('currentVerticalOverlaps:');
		console.log(currentVerticalOverlaps);
		console.log('currentVerticalIntersectionOverlaps:');
		console.log(currentVerticalIntersectionOverlaps);

        //Scan for possible new horizontal barrier positions originating at each square
        for (x = 0; x <= boardSize[0] - this.size; x++) {
            y_coord_loop:
            for (y = 0; y <= boardSize[1] - 2; y++) {
                //Construct the proposed new horizonal position by pushing its blockages into an array
                testPosition = []
                for (i = 0; i < this.size; i++) {
                    testPosition.push([[x+i,y],[x+i,y+1]])
                }
                //Don't allow player to move the barrier back to the same spot
                if (this.sameAsBarrier(testPosition)) { continue y_coord_loop; }

                //See if the proposed position overlaps or intersects with any of the existing walls
                for (i = 0; i < this.size; i++) {
                    coordKey = (x+i) + ',' + y;
                    if (currentHorizontalOverlaps[coordKey]) { continue y_coord_loop; }
                    if (i+1 < this.size) {
                        if (currentHorizontalIntersectionOverlaps[coordKey]) { continue y_coord_loop; }
                    }
                }
                placesYouCanPutThisBarrier.push(testPosition);
            }
        }

        //Scan for possible new vertical barrier positions originating at each square
        for (x = 0; x <= boardSize[0] - 2; x++) {
            y_coord_loop:
            for (y = 0; y <= boardSize[1] - this.size; y++) {
                //Construct the proposed new vertical position by pushing its blockages into an array
                testPosition = []
                for (i = 0; i < this.size; i++) {
                    testPosition.push([[x,y+i],[x+1,y+i]])
                }
                //Don't allow player to move the barrier back to the same spot
                if (this.sameAsBarrier(testPosition)) { continue y_coord_loop; }

                //See if the proposed position overlaps or intersects with any of the existing walls
                for (i = 0; i < this.size; i++) {
                    coordKey = x + ',' + (y+i);
                    if (currentVerticalOverlaps[coordKey]) { continue y_coord_loop; }
                    if (i+1 < this.size) {
                        if (currentVerticalIntersectionOverlaps[coordKey]) { continue y_coord_loop; }
                    }
                }
                placesYouCanPutThisBarrier.push(testPosition);
            }
        }

		console.log('placesYouCanPutThisBarrier:');
		console.log(placesYouCanPutThisBarrier);
		console.log('---------');

		return placesYouCanPutThisBarrier;


	};

*/