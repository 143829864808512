import { Client } from 'boardgame.io/react';
import { Wunby } from './Game';
import { WunbyBoard } from './Board';

const App = Client({
  game: Wunby,
  board: WunbyBoard,
});

export default App;
